<template>
  <v-container fluid>
    <v-card elevation="2">
      <v-toolbar color="light" light>
        <v-icon left>mdi-clipboard-text-outline</v-icon>
        <v-toolbar-title>Approval Decision Report</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-btn icon @click="toggleDarkMode">
          <v-icon>{{ darkMode ? "mdi-weather-sunny" : "mdi-weather-night" }}</v-icon>
        </v-btn> -->
      </v-toolbar>

      <v-card-text>
        <v-tabs
          v-model="activeTab"
          background-color="primary"
          dark
          grow
          slider-color="accent"
        >
          <v-tab v-for="tab in tabs" :key="tab.value" :to="tab.route">
            <v-icon left>{{ tab.icon }}</v-icon>
            {{ tab.label }}
          </v-tab>
        </v-tabs>

        <v-card flat class="mt-4">
          <router-view />
        </v-card>
      </v-card-text>
    </v-card>

    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      top
      :timeout="3000"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      activeTab: null,
      darkMode: false,
      tabs: [
        {
          label: "Pending",
          value: "pending",
          route: "/approvals/pending",
          icon: "mdi-clock-outline",
        },
        {
          label: "Approved",
          value: "approved",
          route: "/approvals/approved",
          icon: "mdi-check-circle-outline",
        },
        {
          label: "Rejected",
          value: "rejected",
          route: "/approvals/rejected",
          icon: "mdi-close-circle-outline",
        },
      ],
      snackbar: {
        show: false,
        text: "",
        color: "info",
      },
    };
  },
  methods: {
    refreshData() {
      this.getApprovals();
    },
    getApprovals() {
      this.$store
        .dispatch("get", `/get-approved-documents`)
        .then(() => {
          this.showSnackbar("Data refreshed successfully", "success");
        })
        .catch((err) => {
          this.showSnackbar(err, "error");
        });
    },
    showSnackbar(text, color = "info") {
      this.snackbar.text = text;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },
    toggleDarkMode() {
      this.darkMode = !this.darkMode;
      this.$vuetify.theme.dark = this.darkMode;
    },
  },
};
</script>
